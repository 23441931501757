<template>
  <div class="w-full">
    <div class="p-fluid flex gap-4">
      <div class="p-field w-1/5 ">
        <label for="fechaInicio">Fecha de inicio</label>
        <input v-model="fechaInicio" id="numeroEnvio" type="date" class="p-inputtext p-component  p-inputtext-sm" />
      </div>
      <div class="p-field w-1/5">
        <label for="fechaFin">Fecha de fin</label>
        <input v-model="fechaFin" id="fechaFin" size="small" type="date" class="p-inputtext p-component  p-inputtext-sm" />
      </div>
      <div class="p-field w-1/5">
        <label for="numeroEnvio">Número de envío</label>
        <InputText v-model="numeroEnvio" id="numeroEnvio" size="small" />
      </div>
      <div class="p-field w-1/5">
        <label for="numeroGuia">Número de guía</label>
        <InputText v-model="numeroGuia" id="numeroGuia" size="small" />
      </div>
      <div class="p-field w-1/5 my-auto">
        <Button label="Buscar" @click="enviarFormulario" size="small" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { useListar } from '../../../store/listar.store'
const _useListar = useListar()
const fechaInicio = ref(null)
const fechaFin = ref(null)
const numeroEnvio = ref(null)
const numeroGuia = ref(null)

const enviarFormulario = () => {
  // Lógica para enviar el formulario
  console.log('Formulario enviado')
  _useListar.filter = {
    fechaInicio: fechaInicio.value,
    fechaFin: fechaFin.value,
    numeroEnvio: numeroEnvio.value,
    numeroGuia: numeroGuia.value
  }
  _useListar.listar()
}
</script>
<style scoped>
.p-field {
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .p-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
